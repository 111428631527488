const mockData = [
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Naik",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 3223
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Fernandes",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1862
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Parab",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 1609
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Shetgaonkar",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 1416
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Sawant",
        "caste": "Maratha",
        "category": "General",
        "religion": "Hindu",
        "count": 965
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "D'Souza",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 882
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Parsekar",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 721
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Mandrekar",
        "caste": "Maratha",
        "category": "OBC",
        "religion": "Hindu",
        "count": 667
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Mhamal",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 582
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Salgaonkar",
        "caste": "Brahmin",
        "category": "General",
        "religion": "Hindu",
        "count": 560
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Rodrigues",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 495
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Pednekar",
        "caste": "Brahmin",
        "category": "General",
        "religion": "Hindu",
        "count": 476
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Gadekar",
        "caste": "Khumbhar",
        "category": "OBC",
        "religion": "Hindu",
        "count": 436
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Harmalkar",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 398
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Kerkar",
        "caste": "Chambhar",
        "category": "SC",
        "religion": "Hindu",
        "count": 380
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Gawade",
        "caste": "Gawda",
        "category": "ST",
        "religion": "Hindu",
        "count": 376
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Talkar",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 353
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Kambli",
        "caste": "Dalit",
        "category": "SC",
        "religion": "Hindu",
        "count": 339
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Chari",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 320
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Thakur",
        "caste": "Kshatriya",
        "category": "General",
        "religion": "Hindu",
        "count": 312
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Palyekar",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 296
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Morje",
        "caste": "Gawda",
        "category": "ST",
        "religion": "Hindu",
        "count": 278
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Morajkar",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 256
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Govekar",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 239
    },
    {
        "assembly_constituency_number": 1,
        "assembly_constituency_name": "Mandrem",
        "last_name": "Vast",
        "caste": "Brahmin",
        "category": "General",
        "religion": "Hindu",
        "count": 224
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Vaz Gracias George",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Batra",
        "caste": "Khatri",
        "category": "General",
        "religion": "Hindu/Sikh",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Jethaji",
        "caste": "Khatri",
        "category": "General",
        "religion": "Hindu/Sikh",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Khangaonkar",
        "caste": "",
        "category": "",
        "religion": "",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Futardo Fernandes",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Murnal",
        "caste": "Unidentified_Christian",
        "category": "Unidentified_Christian",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Ramos",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Ribeiro E. Fernandes",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Tapal",
        "caste": "Muslim",
        "category": "Muslim",
        "religion": "Muslim",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Naique Bhatkar",
        "caste": "Saraswat",
        "category": "General",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Swami",
        "caste": "Brahmin",
        "category": "General",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "D'Souza E Tavares",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Velijee",
        "caste": "Muslim",
        "category": "Muslim",
        "religion": "Muslim",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Uppar",
        "caste": "Unidentified Non Goan",
        "category": "Unidentified Non Goan",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Ferns",
        "caste": "Arora",
        "category": "General",
        "religion": "Hindu/Sikh",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Demelo",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Salker",
        "caste": "Unidentified Non Goan",
        "category": "Unidentified Non Goan",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Isaac",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Bailppagol",
        "caste": "Khatri",
        "category": "General",
        "religion": "Hindu/Sikh",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Sinai Usgaonker",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Balur",
        "caste": "Muslim",
        "category": "Muslim",
        "religion": "Muslim",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Ram Dayal",
        "caste": "Kayastha",
        "category": "General",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Nasan Katti",
        "caste": "Unidentified Non Goan",
        "category": "Unidentified Non Goan",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Jayaprakash",
        "caste": "Unidentified_Hindu",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Mandava",
        "caste": "Muslim",
        "category": "Muslim",
        "religion": "Muslim",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Amlani",
        "caste": "Dalit",
        "category": "SC",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Karepannawar",
        "caste": "Unidentified_Hindu",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Singnapurkar",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Dandigyagol",
        "caste": "",
        "category": "",
        "religion": "",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Chawta",
        "caste": "Unidentified_Hindu",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Aldonkar",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Chavda",
        "caste": "Unidentified_Hindu",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Zuvarkar",
        "caste": "Kayastha",
        "category": "General",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Ramesh",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Hurcan",
        "caste": "",
        "category": "",
        "religion": "",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Chari Priolkar",
        "caste": "Maratha",
        "category": "General",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Kandre",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Aranjo",
        "caste": "Unidentified_Christian",
        "category": "Unidentified_Christian",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Pillewan",
        "caste": "Unidentified_Hindu",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Sayeeda",
        "caste": "Unidentified_Hindu",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Dinekar",
        "caste": "Kayastha",
        "category": "General",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Diana Demello",
        "caste": "Unidentified_Christian",
        "category": "Unidentified_Christian",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Khadeparkar",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Chitre",
        "caste": "Muslim",
        "category": "Muslim",
        "religion": "Muslim",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Sharada",
        "caste": "Unidentified_Hindu",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Vishvakarma",
        "caste": "Tarkhan",
        "category": "OBC",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "T.Pednekar",
        "caste": "Unidentified_Hindu",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Choni",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Mudholkar",
        "caste": "Unidentified Non Goan",
        "category": "Unidentified Non Goan",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Vurgese",
        "caste": "Unidentified_Hindu",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Neroy",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Kuwari",
        "caste": "Unidentified Non Goan",
        "category": "Unidentified Non Goan",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Kumar Pillai",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Dias E Fernandes",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Sinai Kapdi",
        "caste": "Vani",
        "category": "General",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Chalawadi",
        "caste": "Dalit",
        "category": "SC",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Poojary",
        "caste": "Kayastha",
        "category": "General",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Dharmatti",
        "caste": "Unidentified_Hindu",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Bildar",
        "caste": "Devi",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Chimalkar",
        "caste": "Muslim",
        "category": "Muslim",
        "religion": "Muslim",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Curchorkar",
        "caste": "Unidentified_Hindu",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Patted",
        "caste": "Muslim",
        "category": "Muslim",
        "religion": "Muslim",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Ugrankar",
        "caste": "Unidentified_Hindu",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Dhumaskar",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Mapseker",
        "caste": "Jatt",
        "category": "General",
        "religion": "Sikh",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Salgaoker",
        "caste": "Unidentified_Hindu",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Lucas",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Tavora",
        "caste": "",
        "category": "",
        "religion": "",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Naik Sarvoreekar",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Kaushal",
        "caste": "Brahmin",
        "category": "General",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Cherkala Ahammed",
        "caste": "",
        "category": "",
        "religion": "",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Subramanayam",
        "caste": "",
        "category": "",
        "religion": "",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Gomes E Fernandes",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Cherkala",
        "caste": "Muslim",
        "category": "Muslim",
        "religion": "Muslim",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Karsetty",
        "caste": "Unidentified_Hindu",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Karpe",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Abranches",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Periera",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Satardeker",
        "caste": "Unidentified_Hindu",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Karanth Bhat",
        "caste": "Brahmin",
        "category": "General",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Afonso E D Souza",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Gomesh",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Goudagoan",
        "caste": "Unidentified_Hindu",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "De Sequeira Suhkija",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Khorjuwenkar",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Azevedo",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Anigol",
        "caste": "Dalit",
        "category": "SC",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Sheikh Muzawar",
        "caste": "Muslim",
        "category": "Muslim",
        "religion": "Muslim",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Braganza E Machado",
        "caste": "Cathetic Brahmin",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Sebastiao Cardoso",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Pause",
        "caste": "Unidentified_Hindu",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Sidenur",
        "caste": "",
        "category": "",
        "religion": "",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Baretto",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "K. J.",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Silva E Viegas",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Kondicher",
        "caste": "",
        "category": "",
        "religion": "",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Pexiote",
        "caste": "",
        "category": "",
        "religion": "",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Sinai Agani",
        "caste": "Unidentified Non Goan",
        "category": "Unidentified Non Goan",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Sinai Nagorcencar",
        "caste": "Unidentified Non Goan",
        "category": "Unidentified Non Goan",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Tople",
        "caste": "Vaishya Vani",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Shambhu",
        "caste": "Kumar",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Colvalcar",
        "caste": "Baniya",
        "category": "General",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Khan Karol",
        "caste": "Muslim",
        "category": "Muslim",
        "religion": "Muslim",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Sekharan",
        "caste": "Kayastha",
        "category": "General",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Sinai Dhargalkar",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Malgaoker",
        "caste": "Kumbhar",
        "category": "OBC",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Fayaz",
        "caste": "",
        "category": "",
        "religion": "",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Tyappi",
        "caste": "Unidentified_Hindu",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Pinherio",
        "caste": "",
        "category": "",
        "religion": "",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Khan",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "D'Cruz E Fernandes",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Khatun",
        "caste": "Muslim",
        "category": "Muslim",
        "religion": "Muslim",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Patted",
        "caste": "",
        "category": "",
        "religion": "",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Dharwaokar",
        "caste": "Unidentified_Hindu",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Aguiar Barreto",
        "caste": "Catholic",
        "category": "General",
        "religion": "Christian",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Sirsanghi",
        "caste": "Unidentified_Hindu",
        "category": "Unidentified_Hindu",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Kohli",
        "caste": "Khatri",
        "category": "General",
        "religion": "Hindu/Sikh",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Riberio",
        "caste": "",
        "category": "",
        "religion": "",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Sinai Carapurcar",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Khan Desai",
        "caste": "Brahmin",
        "category": "General",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Panvelcar",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Sinai Borkar",
        "caste": "Bhandari Naik",
        "category": "OBC",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Dabholkar Kulkarni",
        "caste": "Brahmin",
        "category": "General",
        "religion": "Hindu",
        "count": 1
    },
    {
        "assembly_constituency_number": 11,
        "assembly_constituency_name": "Panaji",
        "last_name": "Marwaha",
        "caste": "Khatri",
        "category": "General",
        "religion": "Hindu/Sikh",
        "count": 1
    }
]

export default mockData