import React, {useState} from 'react';
import {
    EuiButton,
    EuiButtonEmpty,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiText,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle
} from '@elastic/eui';


export default ({currentCard, onComplete}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const closeModal = () => {
        onComplete('left')
        setIsModalVisible(false);
    }

    const showModal = () => setIsModalVisible(true);

    const formSample = (
        <EuiForm id="modalFormId" component="form">
            <EuiFormRow readOnly label="Assembly">
                <EuiFieldText
                    disabled
                    value={currentCard.assembly_constituency_name}
                    name="assembly"
                />
            </EuiFormRow>
            <EuiFormRow readOnly label="Last Name">
                <EuiFieldText
                    disabled
                    value={currentCard.last_name}
                    name="last_name"
                />
            </EuiFormRow>
            <EuiFormRow label="Caste">
                <EuiFieldText
                    placeholder={currentCard.caste}
                    name="caste"/>
            </EuiFormRow>
            <EuiFormRow label="Category">
                <EuiFieldText name="category" placeholder={currentCard.category}/>
            </EuiFormRow>
            <EuiFormRow label="Religion">
                <EuiFieldText name="religion" placeholder={currentCard.religion}/>
            </EuiFormRow>
        </EuiForm>
    );


    let modal;

    if (isModalVisible) {
        modal = (
            <EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
                <EuiModalHeader>
                    <EuiModalHeaderTitle>
                        <EuiText>Edit</EuiText>
                    </EuiModalHeaderTitle>
                </EuiModalHeader>

                <EuiModalBody>{formSample}</EuiModalBody>

                <EuiModalFooter>
                    <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>

                    <EuiButton type="submit" form="modalFormId" onClick={closeModal} fill>
                        Save
                    </EuiButton>
                </EuiModalFooter>
            </EuiModal>
        );
    }
    return (
        <div>
            <EuiButton onClick={showModal}>Edit</EuiButton>
            {modal}
        </div>
    );
};