import React, {useEffect, useMemo, useState} from 'react'
import TinderCard from 'react-tinder-card'
import './App.css';
import '@elastic/eui/dist/eui_theme_amsterdam_light.css';
import mockData from "./mockData";
import {EuiDescriptionList, EuiFlexGroup, EuiFlexItem, EuiText} from "@elastic/eui/es";
import Modal from "./Modal";

const alreadyRemoved = []
let charactersState = mockData

/**
 *
 * @param obj
 */
const formatToDescriptionAndTitleArray = (obj) => {
    const formattedArray = []
    for (const [key, value] of Object.entries(obj)) {
        formattedArray.push({title: key, description: value})
    }
    return formattedArray
}

const changeOrCancel = () => {

}



function Advanced() {
    const [characters, setCharacters] = useState(mockData)
    const [lastDirection, setLastDirection] = useState()
    const [currentCard, setCurrentCard] = useState({})

    const childRefs = useMemo(() => Array(mockData.length).fill(0).map(i => React.createRef()), [])

    const swiped = (direction, nameToDelete) => {
        console.log('removing: ' + nameToDelete)
        setLastDirection(direction)
        alreadyRemoved.push(nameToDelete)
    }

    const outOfFrame = (last_name) => {
        console.log(last_name + ' left the screen!')
        charactersState = charactersState.filter(character => character.last_name !== last_name)
        setCharacters(charactersState)
    }

    const swipe = (dir) => {
        const cardsLeft = characters.filter(person => !alreadyRemoved.includes(person.last_name))
        if (cardsLeft.length) {
            const toBeRemoved = cardsLeft[cardsLeft.length - 1].last_name // Find the card object to be removed
            const index = mockData.map(person => person.last_name).indexOf(toBeRemoved) // Find the index of which to make the reference to
            alreadyRemoved.push(toBeRemoved) // Make sure the next card gets removed next time if this card do not have time to exit the screen
            childRefs[index].current.swipe(dir) // Swipe the card!
        }
    }

    useEffect(() => {
        const cardsLeft = characters.filter(person => !alreadyRemoved.includes(person.last_name))
        if (cardsLeft.length) {
            return setCurrentCard(cardsLeft[cardsLeft.length - 1])
        }
        return setCurrentCard(null)
    }, [characters, alreadyRemoved])

    return (
        <div>
            <EuiText grow={false}>
                <h1>Goa Surname Details Collector</h1>
            </EuiText>
            <div className='cardContainer'>
                {characters.map((character, index) =>
                    <TinderCard  preventSwipe={['left', 'down']} ref={childRefs[index]} className='swipe' key={`${character.last_name}-${index}`}
                                onSwipe={(dir) => swiped(dir, character.last_name)}
                                onCardLeftScreen={() => outOfFrame(character.last_name)}>
                        <div className='card'>
                            <EuiFlexGroup>
                                <EuiFlexItem>
                                    <EuiDescriptionList listItems={formatToDescriptionAndTitleArray(character)}/>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </div>
                    </TinderCard>
                )}
            </div>
            <div className='buttons'>
                <Modal currentCard={currentCard} onComplete={() => swipe('left')} />
                {/*<button onClick={() => {*/}
                {/*    swipe('left')*/}
                {/*}}> Make correction</button>*/}
                <button onClick={() => swipe('up')}>Skip</button>
                <button onClick={() => swipe('right')}>Approve</button>
            </div>
            {lastDirection ? <h2 key={lastDirection} className='infoText'>You swiped {lastDirection}</h2> :
                <h2 className='infoText'>Swipe a card or press a button to get started!</h2>}
        </div>
    )
}

export default Advanced